import React, { useState, useCallback, useEffect } from 'react';
import CTable from '../../../components/Table/Table';
import { kycHeader, EntriesfilterList, kycFilter } from '../../../constants/mocks/customers';
import { HiDotsVertical } from 'react-icons/hi';
import Dropdown from '../../../components/DropDown/DropDown';
import SearchInput from '../../../components/Input/SearchInput';
import { IoIosArrowDown } from 'react-icons/io';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { UserInitals } from 'utils/ImagePlaceholder';
import useCustomer from 'hooks/fetch/useCustomer';
import { useCustomerState } from 'store/modules/customer';
import { useDebounce } from 'hooks/useDounce';
import usePermissions from 'hooks/usePermissions';
import Filter from 'assets/icons/Filter';
import ViewUtilityBill from './ViewUtilityBill';
import Modal from 'components/Modal/Modal';
import CustomButton from 'components/Button/CustomButton';
import Close from 'assets/icons/CloseIcon';

const CustomerList = ({ tab }) => {
  //Hook
  const { customerList, loading } = useCustomerState();
  const { userIsPermittedTo } = usePermissions();
  //local state
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [perPage, setPerPage] = useState({ label: '10 Entries', value: 10 });
  const [page, setPage] = useState(1);
  //Variable
  const per_page = perPage.value;
  let kyc_status;
  if (tab?.name !== 'all') kyc_status = tab?.value;
  //Hooks
  const { handleGetAllCustomer, upgradeTierAccount } = useCustomer();
  const debouncedSearch = useDebounce(search);
  const filterSearch = () => {
    if (debouncedSearch.trim() !== '') {
      if (status?.value === '0' || status?.value === '1' || status?.value === '2' || status?.value === '3') {
        handleGetAllCustomer({ search: debouncedSearch, per_page, page, kyc_status, tier_level: status?.value });
      } else {
        handleGetAllCustomer({ search: debouncedSearch, per_page, page, kyc_status, status: status?.value });
      }
    } else {
      if (status?.value === '0' || status?.value === '1' || status?.value === '2' || status?.value === '3') {
        handleGetAllCustomer({ per_page, page, kyc_status, tier_level: status?.value });
      } else {
        handleGetAllCustomer({ per_page, page, kyc_status, status: status?.value });
      }
    }
  };
  const [reasonModal, setReasonModal] = useState(false);
  const toggleReasonModal = () => setReasonModal(!reasonModal);
  const [reason, setReason] = useState('');
  //Handler Function
  const upgradeTierLevel = (status, id) => {
    upgradeTierAccount(id, status, reason, () => filterSearch());
  };

  //Effect
  useEffect(() => {
    filterSearch();
  }, [per_page, page, tab?.value, debouncedSearch, status]);
  //Handler function
  const handleChange = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search],
  );
  const handleFilter = useCallback(
    (data) => {
      setStatus(data);
    },
    [status],
  );
  const handlePerPage = useCallback(
    (data) => {
      setPerPage(data);
    },
    [perPage],
  );


  return (
    <>
      <div className="mt-7">
        <div className="flex justify-between items-center mb-6 flex-wrap">
          <p className="font-dmsans_b text-18">Customer’s List</p>
          <div className="flex lg:space-x-4 flex-wrap">
            <div className="flex md:space-x-4 flex-wrap mt-4 lg:mt-0">
              <SearchInput placeholder="Search names, tag" value={search} handleChange={handleChange} />
              <Dropdown
                variant="border border-light-smoke w-[175px] mt-4 md:mt-0"
                placeholder="Filter by Status"
                innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex  items-center"
                icon={<Filter />}
                type="filter"
                value={status}
                options={kycFilter}
                onChange={handleFilter}
              />
            </div>
            <div className="flex items-center mt-4 lg:mt-0">
              <span className="text-14 font-dmsans_r mr-2">Showing:</span>
              <Dropdown
                variant="border border-light-smoke w-[142px]"
                placeholder="10 Entries"
                innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex  items-center"
                icon={<IoIosArrowDown />}
                value={perPage}
                type="filter"
                options={EntriesfilterList}
                onChange={handlePerPage}
              />
            </div>
          </div>
        </div>
        <CTable
          header={kycHeader}
          totalPayload={customerList?.total}
          changePage={setPage}
          perPage={per_page}
          showCheckBox={false}
          currentPage={customerList?.page}
          loading={loading}
          data={customerList?.customers}
        >
          {(item) => (
            <>
              <td
                role="cell"
                data-testid="test-picture"
                // onClick={() => handleViewCustomer(item.user_id)}
                onClick={() => {
                  window.open(`/manage-customer/${item.user_id}`, '_blank');
                }}
                className="cursor-pointer"
              >
                <div className="flex items-center pl-6 space-x-3">
                  <div className="rounded-full product__img flex items-center justify-center">
                    {item.profile_picture && <img src={`${item.profile_picture}`} alt="item" />}
                    {!item.profile_picture && (
                      <p className="uppercase text-gray_dark_100 font-dmsans_m text-14">
                        {UserInitals(item.first_name, item.last_name)}
                      </p>
                    )}
                  </div>
                  <div className="">
                    <p className="text-dark_blue font-dmsans_m min-w-max mx-4 md:mx-0 whitespace-no-wrap capitalize">
                      {item.first_name} {item.last_name}
                    </p>
                    <p className="text-grey font-dmsans_r min-w-max mx-4 md:mx-0 whitespace-no-wrap">
                      @{item.tag ? item.tag : 'N/A'}
                    </p>
                  </div>
                </div>
              </td>
              <td role="cell">
                <p className="font-dmsans_r min-w-max mx-4 md:mx-0 whitespace-no-wrap">
                  {dayjs(item.created_at).format('MMM DD, YYYY')}
                  <span className="text-grey"> | {dayjs(item.created_at).format('h:mm a')}</span>
                </p>
              </td>
              <td role="cell">
                <p className="text-grey-500 font-dmsans_m min-w-[10%] md:mx-0 ">Tier {item.tier_levels}</p>
              </td>

              <td role="cell">
                <p className="font-dmsans_r min-w-max mx-4 md:mx-0 whitespace-no-wrap">
                  {dayjs(item.updated_at).format('MMM DD, YYYY')}
                  <span className="text-grey"> | {dayjs(item.updated_at).format('h:mm a')}</span>
                </p>
              </td>
              <td role="cell">
                <p
                  onClick={() => setSelectedCustomer(item)}
                  className={`${
                    item.utility_bill ? 'text-purple underline cursor-pointer' : 'text-grey-500'
                  }  min-w-[150px] md:min-w-[250px] font-dmsans_m mx-4 md:mx-0 whitespace-no-wrap break-all capitalize `}
                >
                  {item.utility_bill ? 'Image Uploaded' : 'N/A'}
                </p>
              </td>
              {item?.status !== 'deleted' && (
                <td role="cell" data-testid={`table-dropdown-${item.user_id}`} className="">
                  <div className="min-w-[50px] mx-4 md:mx-0 flex items-center justify-center">
                    <Dropdown
                      type="filterIcon"
                      filterIcon={<HiDotsVertical className="text-16 text-dark_blue" />}
                      optionContainerVariant="w-[115px]"
                    >
                      <div className="h-auto">
                        <div
                          key={1}
                          data-testid={`test-view-${item.user_id}`}
                          // className="h-10 hover:bg-smoke cursor-pointer py-3 px-3.5 font-dmsans_r"
                          className="h-fit hover:bg-smoke cursor-pointer py-3 px-3.5 font-dmsans_r"
                          // onClick={() => handleViewCustomer(item.user_id)}
                          onClick={() => {
                            window.open(`/manage-customer/${item.user_id}`, '_blank');
                          }}
                        >
                          View Details
                        </div>
                        {item.utility_bill !== null &&
                          userIsPermittedTo('Update', 'customer_management') &&
                          item?.tier_levels !== 3 && (
                            <>
                              <button
                                type="button"
                                key={2}
                                data-testid={`test-deactivate-${item.user_id}`}
                                onClick={() => upgradeTierLevel('upgrade', item.user_id)}
                                className="h-10 hover:bg-smoke cursor-pointer py-3 px-3.5 font-dmsans_r w-full text-left"
                              >
                                Approve
                              </button>
                              <button
                                type="button"
                                key={3}
                                data-testid={`test-activate-${item.user_id}`}
                                onClick={() => {
                                  setSelectedCustomer(item);
                                  toggleReasonModal();
                                }}
                                className="h-10 hover:bg-smoke cursor-pointer py-3 px-3.5 font-dmsans_r text-red-500 w-full text-left"
                              >
                                Reject
                              </button>
                            </>
                          )}
                      </div>
                    </Dropdown>
                  </div>
                </td>
              )}
            </>
          )}
        </CTable>
        {selectedCustomer && !reasonModal && (
          <Modal
            closeModal={() => {
              setSelectedCustomer();
              setReasonModal(false);
            }}
            title="Kyc Document"
            containerVariant="min-w-[500px]"
          >
            <ViewUtilityBill {...selectedCustomer} />
          </Modal>
        )}
      </div>
      {reasonModal && (
        <div className="fixed inset-0 z-[600] bg-[#0D0F1160] flex items-center justify-center">
          <div className="relative max-h-4/5 w-[90%] max-w-[423px] bg-[#FFFFFF] overflow-hidden rounded-md flex flex-col gap-6 py-6 px-8">
            <div className="pb-4 border-b border-b-light-smoke flex gap-2 justify-between">
              <p className="text-black-200 font-dmsans_b">Reject Utility Bill</p>
              <button
                type="button"
                onClick={() => {
                  setSelectedCustomer();
                  toggleReasonModal();
                }}
              >
                <Close color="#3A434B" />
              </button>
            </div>
            <div className="flex flex-col w-full gap-8">
              <div className="flex flex-col gap-1">
                <label htmlFor="reason" className="font-dmsans_r text-grey text-14 w-fit">
                  Reason
                </label>
                <textarea
                  id="reason"
                  value={reason}
                  placeholder="State reasons for deletion"
                  onChange={(e) => setReason(e.target.value)}
                  className="h-[100px] rounded-lg resize-none font-dmsans_r px-4 py-3 text-16 border-grey-600 border"
                />
              </div>
              <div className="w-full flex justify-end gap-4">
                <CustomButton
                  name="Cancel"
                  variant={`text-purple font-dmsans_r px-0 py-0`}
                  // disabled={loading}
                  onClick={() => {
                    setSelectedCustomer();
                    toggleReasonModal();
                  }}
                />
                <CustomButton
                  name="Proceed"
                  variant={`bg-purple text-white rounded-[4px] font-dmsans_r py-2 px-6`}
                  // loading={loading}
                  disabled={reason === ''}
                  onClick={() => upgradeTierLevel('reject', selectedCustomer?.user_id)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CustomerList;
CustomerList.propTypes = {
  tab: PropTypes.object,
};
