import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchRequest } from 'utils/createAsyncThunk';
import api from '../../../services/axios-instance';
import { admin } from '../../../services/endpoints/admin';

const getWalletDetails = createAsyncThunk('customer/getWalletDetails', async (id) => {
  try {
    const response = await api.get(`/admin-customer/${id}?resource=wallet-details`);
    if (response.data) {
      return response.data;
    }
    return {
      code: response?.response?.data?.code,
      message: response?.response?.data?.message,
      status: response?.response?.data?.message,
    };
  } catch (error) {
    return Promise.reject(error);
  }
});

const getSingleCustomer = createAsyncThunk('customer/getSingleCustomer', async (id) => {
  try {
    const response = await api.get(`/admin-customer/${id}?resource=user-details`);
    if (response.data) {
      return response.data;
    }
    return {
      code: response?.response?.data?.code,
      message: response?.response?.data?.message,
      status: response?.response?.data?.message,
    };
  } catch (error) {
    return Promise.reject(error);
  }
});

const getCustomerReferalTrail = createAsyncThunk('customer/getCustomerReferalTrial', async (id) => {
  try {
    const response = await api.get(`/admin-customer/${id}?resource=referral-trail`);
    if (response.data) {
      return response.data;
    }
    return {
      code: response?.response?.data?.code,
      message: response?.response?.data?.message,
      status: response?.response?.data?.message,
    };
  } catch (error) {
    return Promise.reject(error);
  }
});

const getAllCustomers = createAsyncThunk('customer/getAllCustomer', async (params) => {
  try {
    const response = await api.get(admin.get_customers, { params });
    if (response.data) {
      return response.data;
    }
    return {
      code: response?.response?.data?.code,
      message: response?.response?.data?.message,
      status: response?.response?.data?.message,
    };
  } catch (error) {
    return Promise.reject(error);
  }
});

const deactivateCustomer = createAsyncThunk('customer/deactivateCustomer', async (payload) => {
  try {
    const response = await api.patch(admin.deactivate_customer, { ...payload });
    if (response.data) {
      return response.data;
    }
    return {
      code: response?.response?.data?.code,
      message: response?.response?.data?.message,
      status: response?.response?.data?.message,
    };
  } catch (error) {
    return Promise.reject(error);
  }
});

const activateCustomer = createAsyncThunk('customer/activateCustomer', async (payload) => {
  try {
    const response = await api.patch(admin.activate_customer, { ...payload });
    if (response.data) {
      return response.data;
    }
    return {
      code: response?.response?.data?.code,
      message: response?.response?.data?.message,
      status: response?.response?.data?.message,
    };
  } catch (error) {
    return Promise.reject(error);
  }
});

const updateCard = createAsyncThunk('customer/updateCard', async (payload) => {
  try {
    const response = await api.post(admin.update_card, { ...payload });
    if (response.data) {
      return response.data;
    }
    return {
      code: response?.response?.data?.code,
      message: response?.response?.data?.message,
      status: response?.response?.data?.message,
    };
  } catch (error) {
    return Promise.reject(error);
  }
});

const upgradeAccount = fetchRequest('customer/upgradeAccount', 'patch', admin.upgrade_account);

const getTransactionHistory = createAsyncThunk(
  'customer/getTransactionHistory',
  async ({
    id,
    fromDate: startDate,
    toDate: endDate,
    page,
    perPage,
    transactionType,
    status,
    transactionCategory,
    search,
  }) => {
    try {
      let url = `/admin-customer/${id}?resource=transaction-history&fromDate=${startDate}&toDate=${endDate}&page=${page}&per_page=${perPage}`
      if (transactionType) {
        url += `&transaction_type=${transactionType}`
      }
      if (status) {
        url += `&status=${status}`
      }
      if (transactionCategory) {
        url += `&transaction_category=${transactionCategory}`
      }
      if (search) {
        url += `&search=${search}`
      }
      const response = await api.get(url);
      if (response.data) {
        return response.data;
      }
      return {
        code: response?.response?.data?.code,
        message: response?.response?.data?.message,
        status: response?.response?.data?.message,
      };
    } catch (error) {
      return Promise.reject(error);
    }
  },
);
const getProductHistory = createAsyncThunk(
  'customer/getProductHistory',
  async ({ id, fromDate: startDate, toDate: endDate }) => {
    try {
      const response = await api.get(
        `/admin-customer/${id}?resource=product-analytics&fromDate=${startDate}&toDate=${endDate}`,
      );
      if (response.data) {
        return response.data;
      }
      return {
        code: response?.response?.data?.code,
        message: response?.response?.data?.message,
        status: response?.response?.data?.message,
      };
    } catch (error) {
      return Promise.reject(error);
    }
  },
);
const getTransactionReceipt = createAsyncThunk('customer/getTransactionReceipt', async (payload) => {
  try {
    const response = await api.get(
      `/admin-customer/${payload.id}?resource=transaction-receipt&transaction_id=${payload.transaction_id}`,
    );
    if (response.data) {
      return response.data;
    }
    return {
      code: response?.response?.data?.code,
      message: response?.response?.data?.message,
      status: response?.response?.data?.message,
    };
  } catch (error) {
    return Promise.reject(error);
  }
});

const updateCustomerWallet = createAsyncThunk('customer/updateCustomerWallet', async ({ params, payload }) => {
  try {
    const response = await api.post(`/admin-customer/${params.id}/${params.type}`, {
      ...payload,
    });
    if (response.data) {
      return response.data;
    } else if (response.response.data) {
      return response.response.data;
    }
    return {};
    // return response.data ? response.data : {};
  } catch (error) {
    return Promise.reject(error);
  }
});

const deleteCustomerApi = createAsyncThunk('customer/deleteCustomerApi', async (payload) => {
  try {
    const response = await api.patch(admin.delete_customer, { ...payload });
    if (response.data) {
      return response.data;
    }
    return {
      code: response?.response?.data?.code,
      message: response?.response?.data?.message,
      status: response?.response?.data?.message,
    };
  } catch (error) {
    return Promise.reject(error);
  }
});

export {
  upgradeAccount,
  getTransactionReceipt,
  getAllCustomers,
  getSingleCustomer,
  getWalletDetails,
  getProductHistory,
  deactivateCustomer,
  activateCustomer,
  updateCard,
  getTransactionHistory,
  getCustomerReferalTrail,
  updateCustomerWallet,
  deleteCustomerApi,
};
