/* eslint-disable max-len */
import React, { useState, useCallback, useEffect } from 'react';
import BackButton from '../../../components/Button/BackButton';
import CustomButton from '../../../components/Button/CustomButton';
import Tabs from '../../../components/Tabs/Tabs';
import { useAuthState } from '../../../store/modules/auth';
import General from '../../../components/Customers/General/General';
import { UserInitals } from 'utils/ImagePlaceholder';
import { formatCurrency } from 'utils/formatCurrency';
import useCustomer from 'hooks/fetch/useCustomer';
import { useCustomerState } from 'store/modules/customer';
import usePermissions from 'hooks/usePermissions';
import Dropdown from 'components/DropDown/DropDown';
import { HiDotsVertical } from 'react-icons/hi';
import Modal from 'components/Modal/Modal';
import DeductMoneyForm from './DeductMoney';
import AddMoneyForm from './AddMoney';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { notify } from 'store/modules/global';
import { useAppDispatch } from 'hooks/useAppDispatch';
import Close from 'assets/icons/CloseIcon';
import EyeIcon from 'assets/icons/EyeIcon';
import Input from 'components/Input/Input';

const ManageCustomer = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  //Variables
  const authState = useAuthState();
  const customerState = useCustomerState();
  let TabsHeader = ['General'];
  const {
    loggedUser: { role },
  } = useAuthState();

  //Custom hooks
  const { loading } = authState;
  const { userIsPermittedTo } = usePermissions();
  //local state
  const [selectedTab, setSelectedTab] = useState('General');
  //Hooks
  const { deactivatecustomer, activatecustomer, deleteCustomer, handleViewCustomer } = useCustomer();
  //Handler Function
  const handleDeactivate = useCallback(() => {
    deactivatecustomer(id, () => handleViewCustomer(id));
  }, []);
  const handleActivate = useCallback(() => {
    activatecustomer(id, () => handleViewCustomer(id));
  }, []);
  const [reason, setReason] = useState('');
  const [password, setPassword] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const toggleOpenDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };
  const [step, setStep] = useState(1);
  const switchStep = (value) => {
    setStep(value);
  };
  const closeDeleteModal = () => {
    toggleOpenDeleteModal();
    setReason('');
    switchStep(1);
  };

  const handleDelete = () => {
    deleteCustomer({ user_id: id, reason, password }, () => (window.location.href = '/manage-customer'));
  };

  const closeModal = () => {
    setModalType('');
    setShowModal(false);
    setOpenPasswordModal(false);
  };
  const handleShowDeductMoneyForm = () => {
    if (customerState?.selectedCustomer?.wallet_id) {
      setModalType('Deduct Money');
      setShowModal(true);
    } else {
      dispatch(
        notify({
          display: true,
          status: 'error',
          message: 'This user is without a wallet.',
        }),
      );
    }
  };

  const handleShowAddMoneyForm = () => {
    if (customerState?.selectedCustomer?.wallet_id) {
      setModalType('Add Money');
      setShowModal(true);
    } else {
      dispatch(
        notify({
          display: true,
          status: 'error',
          message: 'This user is without a wallet.',
        }),
      );
    }
  };

  const [fullPicture, setFullPicture] = useState(false);
  const toggleFullPicture = () => setFullPicture(!fullPicture);

  useEffect(() => {
    document.title = 'Manage Customer - Pouchers Admin';
  }, []);

  useEffect(() => {
    handleViewCustomer(id);
    return () => handleViewCustomer(id);
  }, []);

  return (
    <>
      {customerState?.selectedCustomer?.user_id && (
        <>
          <div>
            <div className="p-32 bg-white rounded-2xl">
              <div className="flex justify-between items-center">
                <Link onClick={() => (window.location.href = '/manage-customer')}>
                  <BackButton />
                </Link>
                <div className="flex items-center gap-2">
                  {userIsPermittedTo('Update', 'customer_management') && (
                    <CustomButton
                      name={customerState?.selectedCustomer?.status !== 'deactivated' ? 'Deactivate' : 'Activate'}
                      variant={`${
                        customerState?.selectedCustomer?.status !== 'deactivated' ? 'bg-danger-red' : 'bg-green'
                      } bg-danger-red text-white rounded-lg font-dmsans_m py-3 px-6`}
                      loading={loading}
                      disabled={loading}
                      onClick={
                        customerState?.selectedCustomer?.status !== 'deactivated' ? handleDeactivate : handleActivate
                      }
                    />
                  )}
                  {role === 'Super-Admin' && (
                    <CustomButton
                      name="Delete"
                      variant={`${
                        customerState?.selectedCustomer?.status !== 'deactivated' ? 'bg-danger-red' : 'bg-green'
                      } bg-danger-red text-white rounded-lg font-dmsans_m py-3 px-6`}
                      loading={loading}
                      disabled={loading}
                      onClick={toggleOpenDeleteModal}
                    />
                  )}
                </div>
              </div>
              <div className="relative mt-[92px] mb-[15px]">
                <div className=" rounded-full absolute top-[-36px] bottom-0 left-7 w-[80px] h-[80px] bg-grey-400 flex items-center justify-center">
                  {customerState?.selectedCustomer?.profile_picture && (
                    <img
                      src={customerState?.selectedCustomer?.profile_picture}
                      alt="profile"
                      className="profile_picture"
                    />
                  )}
                  {!customerState?.selectedCustomer?.profile_picture && (
                    <p className="uppercase text-gray_dark_100 font-dmsans_m text-32">
                      {UserInitals(
                        customerState?.selectedCustomer?.first_name,
                        customerState?.selectedCustomer?.last_name,
                      )}
                    </p>
                  )}
                  <div className="group overflow-hidden h-full w-full absolute bottom-0 left-0 rounded-full">
                    <button
                      type="button"
                      onClick={toggleFullPicture}
                      className="h-2/5 w-full bg-[#00000080] group-hover:bg-purple bottom-0 absolute flex items-center justify-center"
                    >
                      <EyeIcon />
                    </button>
                  </div>
                </div>

                <div className="bg-black-300 px-40 py-5 w-[700px] rounded-[10px] text-white flex justify-between space-x-16 items-center">
                  <div className="text-15 font-dmsans_r ml-[90px]">
                    <p className="capitalize">
                      {customerState?.selectedCustomer?.first_name} {customerState?.selectedCustomer?.last_name}
                    </p>
                    <p className="mt-2">
                      Account - <span>{customerState?.selectedCustomer?.account_number || 'N/A'}</span>
                    </p>
                  </div>
                  <div className="flex justify-between space-x-16 items-center">
                    <div>
                      <p className="text-32 font-dmsans_m">
                        {formatCurrency(customerState?.selectedCustomer?.wallet_balance || '00.00')}
                      </p>
                    </div>

                    <Dropdown
                      type="filterIcon"
                      filterIcon={
                        <div className="flex items-center ml-2">
                          <HiDotsVertical />
                        </div>
                      }
                      optionContainerVariant="w-[100px]"
                    >
                      <div className="h-auto">
                        <div
                          key={1}
                          className="h-10 hover:bg-smoke cursor-pointer py-2 px-3.5 font-dmsans_r"
                          onClick={() => handleShowDeductMoneyForm()}
                        >
                          <p className="text-dark_blue text-14 font-dmsans_m">Deduct</p>
                        </div>
                        <div
                          key={2}
                          className="h-10 hover:bg-smoke cursor-pointer py-2 px-3.5 font-dmsans_r"
                          onClick={() => handleShowAddMoneyForm()}
                        >
                          <p className="text-dark_blue text-14 font-dmsans_r cursor-pointer">Add</p>
                        </div>
                      </div>
                    </Dropdown>
                  </div>
                </div>
              </div>
              {/* <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} TabsItem={TabsHeader} />
            {selectedTab === 'General' && <General />} */}
              <General />
            </div>
            {showModal && modalType ? (
              <Modal
                closeModal={closeModal}
                title={!openPasswordModal ? modalType : 'Enter Password'}
                containerVariant="min-w-[459px]"
              >
                {modalType === 'Deduct Money' ? (
                  <DeductMoneyForm
                    cancel={closeModal}
                    openPasswordModal={openPasswordModal}
                    setOpenPasswordModal={setOpenPasswordModal}
                    customer_id={customerState?.selectedCustomer?.user_id}
                    wallet_balance={formatCurrency(customerState?.selectedCustomer?.wallet_balance || '00.00')}
                  />
                ) : (
                  <AddMoneyForm
                    cancel={closeModal}
                    openPasswordModal={openPasswordModal}
                    setOpenPasswordModal={setOpenPasswordModal}
                    customer_id={customerState?.selectedCustomer?.user_id}
                    wallet_balance={formatCurrency(customerState?.selectedCustomer?.wallet_balance || '00.00')}
                  />
                )}
              </Modal>
            ) : null}
          </div>
          {fullPicture && (
            <div className="fixed inset-0 bg-[#00000060] flex items-center justify-center">
              <div className="relative h-4/5 w-[90%] max-h-[691px] max-w-[990px] bg-[#eee] flex items-center justify-center overflow-hidden">
                {customerState?.selectedCustomer?.profile_picture && (
                  <img
                    src={customerState?.selectedCustomer?.profile_picture}
                    alt="profile"
                    className="object-contain absolute h-full w-full"
                  />
                )}
                {!customerState?.selectedCustomer?.profile_picture && (
                  <div className="h-[150px] w-[150px] md:h-[350px] md:w-[350px] flex items-center justify-center bg-purple m-auto">
                    <p className="uppercase text-white font-dmsans_m text-40 md:text-[200px] text-center">
                      {UserInitals(
                        customerState?.selectedCustomer?.first_name,
                        customerState?.selectedCustomer?.last_name,
                      )}
                    </p>
                  </div>
                )}
                <button
                  type="button"
                  data-testid="closebtn"
                  className="cursor-pointer w-[32px] h-[32px] bg-white flex items-center justify-center rounded-full absolute top-[5.78%] right-[5%] max-right-[7.98%]"
                  onClick={toggleFullPicture}
                >
                  <Close color="#000" />
                </button>
              </div>
            </div>
          )}
          {openDeleteModal && (
            <div className="fixed inset-0 bg-[#0D0F1160] flex items-center justify-center">
              <div className="relative max-h-4/5 w-[90%] max-w-[423px] bg-[#FFFFFF] overflow-hidden rounded-md flex flex-col gap-6 py-6 px-8">
                <div className="pb-4 border-b border-b-light-smoke flex gap-2 justify-between">
                  <p className="text-black-200 font-dmsans_b">{step === 1 ? 'Delete Customer' : 'Enter Password'}</p>
                  <button type="button" onClick={closeDeleteModal}>
                    <Close color="#3A434B" />
                  </button>
                </div>
                {step === 1 ? (
                  <div className="flex flex-col w-full gap-8">
                    <p className="text-sm text-grey">
                      Are you sure you want to delete customer? Please give reason below.{' '}
                    </p>
                    <div className="flex flex-col gap-1">
                      <label htmlFor="reason" className="font-dmsans_r text-grey text-14 w-fit">
                        Reason
                      </label>
                      <textarea
                        id="reason"
                        value={reason}
                        placeholder="State reasons for deletion"
                        onChange={(e) => setReason(e.target.value)}
                        className="h-[100px] rounded-lg resize-none font-dmsans_r px-4 py-3 text-16 border-grey-600 border"
                      />
                    </div>
                    <div className="w-full flex justify-end gap-4">
                      <CustomButton
                        name="Cancel"
                        variant={`text-purple font-dmsans_r px-0 py-0`}
                        disabled={loading}
                        onClick={closeDeleteModal}
                      />
                      <CustomButton
                        name="Delete"
                        variant={`bg-danger-red text-white rounded-[4px] font-dmsans_r py-2 px-6`}
                        loading={loading}
                        disabled={loading || reason === ''}
                        onClick={() => switchStep(2)}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col w-full gap-8">
                    <div className="flex flex-col gap-1">
                      <Input
                        label="Password"
                        type="password"
                        placeholder="Password"
                        id="password"
                        name="password"
                        readOnly={loading}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="w-full flex justify-end gap-4">
                      <CustomButton
                        name="Cancel"
                        variant={`text-purple font-dmsans_r px-0 py-0`}
                        disabled={loading}
                        onClick={closeDeleteModal}
                      />
                      <CustomButton
                        name="Proceed"
                        variant={`bg-purple text-white rounded-[4px] font-dmsans_r py-2 px-6`}
                        loading={loading}
                        disabled={loading || reason === ''}
                        onClick={handleDelete}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {customerState?.loading === false && customerState?.selectedCustomer === undefined && <>USER NOT FOUND</>}
    </>
  );
};

export default ManageCustomer;
