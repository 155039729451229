import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Logo from '../../assets/icons/Logo';
import Dropdown from '../DropDown/DropDown';
import WhiteLogo from '../../assets/icons/WhiteLogo';
import ArrowDown from '../../assets/icons/ArrowDown';
import { logout } from '../../store/modules/auth/actions';
import MobileSidebar from 'components/Sidebar/MobileSidebar';
import MenuOpen from '../../assets/icons/Iconly/menu-open.svg';
import MenuClose from '../../assets/icons/Iconly/menu-close.svg';

export default function NavBar() {
  const { loggedUser } = useSelector((state) => state.auth);
  const intials = loggedUser?.first_name?.split('')[0];
  let dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const toggleMenu = () => setMenu(!menu);
  const closeMenu = () => setMenu(false);

  return (
    <>
      <div className="w-full bg-purple z-10 fixed lg:sticky lg:top-0 drop-shadow-2xl lg:drop-shadow-md">
        <header className="flex justify-between  items-center sm:z-10 px-32 py-14">
          <div className="lg:flex items-center">
            <WhiteLogo />
          </div>
          <div className="flex items-center gap-5">
            <Dropdown
              type="filterIcon"
              filterIcon={
                <div className="flex items-center ml-16">
                  <div className="w-10 h-10 border-2 border-grey rounded-full mr-3.5">
                    <div className="bg-white mt-0.5 w-8 h-8 m-auto rounded-full flex justify-center items-center text-base font-dmsans_b">
                      {intials}
                    </div>
                  </div>
                  <ArrowDown />
                </div>
              }
              optionContainerVariant="w-[186px]"
            >
              <div className="p-4">
                <div>
                  <p className="text-purple text-14 font-dmsans_m">
                    {loggedUser?.first_name} {loggedUser?.last_name}
                  </p>
                  <p className="text-grey text-12 font-dmsans_r mt-0.5">{loggedUser?.email}</p>
                </div>
                <hr className="my-4 h-px border-0 bg-light-smoke" />
                <div className="flex flex-col gap-2">
                  <Link to="/manage-admin/profile" className="text-dark_blue text-14 font-dmsans_r cursor-pointer">
                    Profile
                  </Link>
                  <button
                    type="button"
                    className="text-red mt-2 text-14 font-dmsans_r cursor-pointer w-fit"
                    onClick={() => dispatch(logout())}
                  >
                    Log Out
                  </button>
                </div>
              </div>
            </Dropdown>
            <div className="xl:hidden">
              <button type="button" onClick={toggleMenu}>
                {!menu && <img alt="close-menu" src={MenuClose} />}
                {menu && <img alt="open-menu" src={MenuOpen} />}
              </button>
            </div>
          </div>
        </header>
      </div>
      {menu && (
        <div onClick={toggleMenu} className="fixed inset-0 bg-[#00000030] z-30 xl:hidden">
          <div className="bg-white h-full w-fit">
            <div className="flex items-center px-32 py-[23px] bg-purple">
              <WhiteLogo />
            </div>
            <MobileSidebar closeMenu={closeMenu} />
          </div>
        </div>
      )}
    </>
  );
}
