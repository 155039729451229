import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HiCheck } from 'react-icons/hi';
import { IoIosClose } from 'react-icons/io';
import VerifiedCheck from '../../assets/icons/VerifiedCheck';
import Danger from 'assets/icons/Danger';

const Status = ({ status, showIcon = false }) => {
  const [statusColor, setStatusColor] = useState('');
  const [iconType, setIconType] = useState('');

  function StatusStwitch() {
    switch (status) {
      case 'active':
      case 'completed':
      case 'successful':
      case 'paid':
      case 'success':
      case 'verified':
      case 'added':
        return setStatusColor('bg-green-100 text-deep_green');
      case 'deactivated':
      case 'not active':
      case 'inactive':
      case 'not paid':
      case 'deducted':
      case 'deleted':
      case 'revoked':
        return setStatusColor('bg-red-50 text-red-700');
      case 'suspended':
      case 'claimed':
      case 'pending':
      case 'scheduled':
        return setStatusColor('bg-orange-50 text-orange-700');
      case 'reversed':
        return setStatusColor('bg-[#8F8E9B1A] text-[#8F8E9B]');
      default:
        return setStatusColor('bg-grey-500 text-gray-200');
    }
  }
  function IconSwitch() {
    switch (status) {
      case 'completed':
      case 'active':
      case 'paid':
      case 'successful':
      case 'success':
        return setIconType(<HiCheck className="mr-1.5 text-green-500 text-16" />);
      case 'verified':
        return setIconType(
          <p className="mr-1.5">
            <VerifiedCheck />
          </p>,
        );
      case 'pending':
        return setIconType(
          <p className="mr-1.5">
            <Danger />
          </p>,
        );
      case 'deactivated':
      case 'not active':
      case 'inactive':
      case 'not paid':
      case 'revoked':
      case 'deleted':
        return setIconType(<IoIosClose className="text-red-500 mr-1.5 text-24" />);
      case 'suspended':
      case 'claimed':
      case 'scheduled':
        return setIconType(<IoIosClose className="text-orange-500 mr-1.5 text-24" />);
    }
  }

  useEffect(() => {
    IconSwitch();
    StatusStwitch();
  }, [status]);

  return (
    <div
      className={`${statusColor} py-1 px-3 rounded-2xl capitalize text-xs flex items-center justify-center font-dmsans_m w-max`}
    >
      {showIcon && iconType} <p className="text-12">{status}</p>
    </div>
  );
};

export default Status;

Status.propTypes = {
  status: PropTypes.string,
  showIcon: PropTypes.bool,
};
