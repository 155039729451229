import React from 'react';
import PropTypes from 'prop-types';

import Card from './Card';
import { formatCurrency } from 'utils/formatCurrency';
import ArrowUp from 'assets/icons/ArrowUp';

const DashboardTopCard = ({ amount = false, data, dollar = false, label, loading, percent }) => {
  const amountCurrency = dollar ? '$...' : '₦...';
  return (
    <Card
      type="custom"
      containerVariant="py-[10px] px-[24px] border border-[#D7B7FD] rounded-[8px] bg-white flex-grow flex"
      cardBody={
        <div className="w-full">
          <p className="text-14 font-dmsans_m text-grey">{label}</p>
          {percent ? (
            <div className="flex items-center gap-3 justify-between w-full">
              <p className="text-24 text-gray-900 font-dmsans_b mt-1">
                {loading ? <>{amount ? '₦....' : '...'}</> : <>{amount ? <>{formatCurrency(data)}</> : <>{data}</>}</>}
              </p>
              <div className="flex items-center gap-[4px] bg-[#ECFDF3] py-[2px] pr-[8px] pl-[10px] rounded-[16px]">
                {percent > 0 && <ArrowUp />}
                <p className="text-[#027A48] font-dmsans_m text-14">{percent}%</p>
              </div>
            </div>
          ) : (
            <p className="text-24 text-gray-900 font-dmsans_b mt-1">
              {loading ? (
                <>{amount ? amountCurrency : '...'}</>
              ) : (
                <>
                  {amount ? (
                    <>{formatCurrency(data, dollar ? 'USD' : 'NGN')}</>
                  ) : (
                    <>
                      {data?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </>
                  )}
                </>
              )}
            </p>
          )}
        </div>
      }
    />
  );
};

export default DashboardTopCard;

DashboardTopCard.propTypes = {
  amount: PropTypes.bool,
  data: PropTypes.any,
  dollar: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  percent: PropTypes.string,
};
