import Close from 'assets/icons/CloseIcon';
import CustomButton from 'components/Button/CustomButton';
import useCustomer from 'hooks/fetch/useCustomer';
import usePermissions from 'hooks/usePermissions';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ViewUtilityBill = (selectedCustomer) => {
  //Hooks
  const { upgradeTierAccount } = useCustomer();

  const navigate = useNavigate();
  const { userIsPermittedTo } = usePermissions();

  const [reasonModal, setReasonModal] = useState(false);
  const toggleReasonModal = () => setReasonModal(!reasonModal);
  const [reason, setReason] = useState('');
  //Handler Function
  const upgradeTierLevel = (status) => {
    upgradeTierAccount(selectedCustomer.user_id, status, reason, () => navigate(0));
  };

  return (
    <>
      {!reasonModal && (
        <div className="my-5">
          {selectedCustomer.utility_bill ? (
            <img
              src={`${
                selectedCustomer.utility_bill.includes('amazonaws')
                  ? selectedCustomer.utility_bill
                  : 'https://photow-profile-images.s3.us-west-2.amazonaws.com/' + selectedCustomer.utility_bill
              } `}
              // src='https://poucher-upload.s3.amazonaws.com/20230417-125758pouuch.png'
              alt="utility bill"
              className="w-full object-contain max-h-[700px]"
            />
          ) : (
            <p className="text-center text-grey-600 font-dmsans_m uppercase"> No Utility Bill Available</p>
          )}

          {selectedCustomer.utility_bill &&
            selectedCustomer.tier_levels !== 3 &&
            userIsPermittedTo('Update', 'customer_management') && (
              <div className="flex space-x-6 mt-10 justify-end items-center">
                <CustomButton
                  name="Approve"
                  variant="border border-purple bg-purple text-white rounded font-dmsans_r"
                  onClick={() => upgradeTierLevel('upgrade')}
                />
                <button
                  type="button"
                  className="text-red-200 rounded font-dmsans_r cursor-pointer"
                  onClick={toggleReasonModal}
                >
                  Reject
                </button>
              </div>
            )}
        </div>
      )}
      {reasonModal && (
        <div className="flex flex-col w-full gap-8 py-8">
          <div className="flex flex-col gap-1">
            <label htmlFor="reason" className="font-dmsans_r text-grey text-14 w-fit">
              Reason
            </label>
            <textarea
              id="reason"
              value={reason}
              placeholder="State reason for rejection"
              onChange={(e) => setReason(e.target.value)}
              className="h-[100px] rounded-lg resize-none font-dmsans_r px-4 py-3 text-16 border-grey-600 border"
            />
          </div>
          <div className="w-full flex justify-end gap-4">
            <CustomButton name="Cancel" variant={`text-purple font-dmsans_r px-0 py-0`} onClick={toggleReasonModal} />
            <CustomButton
              name="Reject"
              variant={`bg-danger-red text-white rounded-[4px] font-dmsans_r py-2 px-6`}
              disabled={reason === ''}
              onClick={() => upgradeTierLevel('reject')}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ViewUtilityBill;
